import Axios, { AxiosStatic } from 'axios';
import { get } from 'lodash';
import { Format } from '@/services';
import { Entry, GasEntry, LiquidEntry, SerieEntry, SilDeviceType } from '@/types';

export interface ValveParameters {
  ValveMotion?: string;
  Type?: string;
  Size?: number;
  PressureClass?: string;
  DesignStyle?: string;
  TrimOption?: string;
  SeatCode?: string;
  SizeReduction?: number;
}

export interface RotaryValveParameters {
  Series?: string;
  Size?: number;
  DesignStyle?: string;
  PressureClass?: string;
  PackingType?: string;
  BearingMaterial?: string;
  TrimPressureClass?: string;
  FlowDirection?: string;
  SeatType?: string;
  SeatAmount?: number;
}

class ListService {
  private http: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.http = axios;
  }

  async valveTypes(params: ValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ControlValveType', {
      params
    });

    return ListService.toEntries(response);
  }

  async valveSeriesControl(params: ValveParameters = {}): Promise<SerieEntry[]> {
    const response = await this.http.get('/UILists/api/ControlValveSeriesData', { params });

    return ListService.toSerieEntries(response);
  }

  async valveSeriesOnOff(params: ValveParameters = {}): Promise<SerieEntry[]> {
    const response = await this.http.get('/UILists/api/OnOffValveSeries', {
      params
    });

    return ListService.toSerieEntries(response);
  }

  async valveSizes(params: ValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ControlValveSize', {
      params
    });

    return ListService.toSizeEntries(response);
  }

  async pressureClasses(params: ValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ControlValvePressureClass', { params });

    return ListService.toEntries(response);
  }

  async schedules(params = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/Schedules', {
      params
    });
    return ListService.toScheduleEntries(response);
  }

  async pipeSizes(params = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/PipeSizes', {
      params
    });
    return ListService.toScheduleEntries(response);
  }

  async seatCodes(params: ValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ControlValveSeatCode', {
      params
    });

    return ListService.toEntries(response);
  }

  async sizeReductions(params: ValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ControlValveSizeReduction', {
      params
    });

    return ListService.toEntries(response);
  }

  async trimOptions(params: ValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ControlValveTrimOption', {
      params
    });

    return ListService.toEntries(response);
  }

  async designStyles(params: ValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ControlValveDesignStyle', { params });

    return ListService.toEntries(response);
  }

  async flowDirections(params: ValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ControlValveFlowDirection', { params });

    return ListService.toEntries(response);
  }

  async trimCharacteristics(params: ValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ControlValveTrimCharacteristics', { params });

    return ListService.toEntries(response);
  }

  async seatTypes(params: RotaryValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ValveSeatType', {
      params
    });

    return ListService.toEntries(response);
  }

  async bearingMaterials(params: RotaryValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ValveBearingMaterial', {
      params
    });

    return ListService.toEntries(response);
  }

  async packingMaterials(params: RotaryValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ValvePackingMaterial', {
      params
    });

    return ListService.toEntries(response);
  }

  async seatAmounts(params: RotaryValveParameters = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ValveSeatAmount', {
      params
    });

    return ListService.toEntries(response);
  }

  async trimTypes(params: any = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ValveTrimType', {
      params
    });

    return ListService.toEntries(response);
  }

  async seatLeakages(params: any = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ValveSeatLeakage', {
      params
    });

    return ListService.toEntries(response);
  }

  async bonnetTypes(params: any = {}): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/ValveBonnetType', {
      params
    });

    return ListService.toEntries(response);
  }
  async unitFactors(unitType: string, special?: string): Promise<Entry[]> {
    const params: any = { unitType };

    if (special) {
      params.special = special;
    }

    const response = await this.http.get('/UILists/api/UnitFactors', {
      params
    });

    return ListService.toUnitEntries(response);
  }

  async liquids(): Promise<LiquidEntry[]> {
    const response = await this.http.get('/UILists/api/Liquids');

    return ListService.toLiquidEntries(response);
  }

  async gases(): Promise<GasEntry[]> {
    const response = await this.http.get('/UILists/api/Gases');

    return ListService.toGasEntries(response);
  }

  async attenuatorTypes(params?: any): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/AttenuatorType', {
      params: { ...params }
    });

    return ListService.toEntries(response);
  }

  async attenuatorSizes(params?: any): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/AttenuatorSize', {
      params: { ...params }
    });

    return ListService.toSizeEntries(response);
  }

  async insulationTypes(): Promise<Entry[]> {
    const response = await this.http.get('/UILists/api/InsulationType');

    return ListService.toEntries(response);
  }

  async actuatorSeries(params: any = {}): Promise<SerieEntry[]> {
    const response = await this.http.get('/actuators/ActuatorSeries', {
      params
    });

    return ListService.toSerieEntries(response);
  }

  async springActions(params: any = {}): Promise<Entry[]> {
    const response = await this.http.get('/actuators/ActuatorSpringAction', {
      params
    });

    return ListService.toEntries(response);
  }

  async actuatorSizes(params: any = {}): Promise<Entry[]> {
    const response = await this.http.get('/actuators/ActuatorSize', {
      params
    });

    return ListService.toEntries(response);
  }

  async springRanges(params: any = {}): Promise<Entry[]> {
    const response = await this.http.get('/actuators/ActuatorSpringRange', {
      params
    });

    return ListService.toEntries(response);
  }

  async silDeviceNames(deviceType: SilDeviceType): Promise<Entry[]> {
    const response = await this.http.get('/sil/deviceNames', {
      params: { deviceType }
    });
    return ListService.toEntries(response);
  }

  private static toEntries(response: any): Entry[] {
    return get(response, ['data'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({
        name: item.item,
        tooltip: item.tooltip,
        defaultSelection: item.defaultSelection,
        active: item.active
      }));
  }

  private static toScheduleEntries(response: any): Entry[] {
    return get(response, ['data'], [])
      .filter((entry: any) => !!entry)
      .map((entry: any) => ({
        value: entry.value,
        name: entry.name,
        active: entry.active
      }));
  }

  private static toSizeEntries(response: any): Entry[] {
    return get(response, ['data'], [])
      .filter((entry: any) => !!entry)
      .map((entry: any) => ({
        value: entry.item,
        name: Format.size(entry.item)
      }));
  }

  private static toSerieEntries(response: any): SerieEntry[] {
    return get(response, ['data'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({
        name: item.item,
        motion: item.motion,
        type: item.type,
        imoLink: item.imoLink,
        bulletinLink: item.bulletinLink,
        active: item.active
      }));
  }

  private static toUnitEntries(response: any): Entry[] {
    return get(response, ['data', 'unitFactors'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({ name: item.unit }));
  }

  private static toLiquidEntries(response: any): LiquidEntry[] {
    return get(response, ['data', 'liquids'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({
        name: item.fluid,
        density: item.density,
        criticalPressure: item.criticalPressure,
        specificGravity: item.specificGravity,
        defaultSelection: item.defaultSelection
      }));
  }

  private static toGasEntries(response: any): GasEntry[] {
    return get(response, ['data', 'gases'], [])
      .filter((entry: any) => !!entry)
      .map((item: any) => ({
        name: item.fluid,
        molecularWeight: item.molecularWeight,
        specificHeats: item.specificHeats,
        defaultSelection: item.defaultSelection
      }));
  }
}

const listService = new ListService(Axios);

export { listService };
